import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "../static/css/responsive.css";
import "../static/css/index.css";
import "@/core/styles/main.scss";
import _App from "./_App";
import TagManager from "react-gtm-module";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MSWWrapper } from "@/lib/msw"; // Ensure this path is correctly resolved
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory"; // Ensure this path is correctly resolved
import * as Sentry from "@sentry/react";
import { ExperimentTracker } from "./mixpanel-tracking/hp/experiment-tracker";
import { initializeFeatureFlag } from "./feature-flag"; // Ensure the correct path
import ScalersLoader from "./components/molecules/layout/loader/scalers-loader";

const queryClient = new QueryClient();
const tagManagerArgs = {
    gtmId: "GTM-TG74X39",
};
TagManager.initialize(tagManagerArgs);

const factory = new Factory();
const track = factory.get_tracker(
    Pages.EXPERIMENT_TRACKER
) as ExperimentTracker;

// Set up sentry
if (import.meta.env.VITE_STAGE === "production") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_STAGE,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["hired.withscalers", /^\//],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.5, // Capture 50% of the transactions
    });
}

export const gb = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY || "local",
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        track.captureExperimentsGB(experiment, result);
    },
});

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

const isLocal = import.meta.env.VITE_STAGE === "local";
const isMswEnabled = import.meta.env.VITE_MSW_ENABLED === "true";
interface Props {
    children: React.ReactNode;
}
const AppWrapper = (props: Props) => {
    if (isLocal) {
        return (
            <React.StrictMode>
                {isMswEnabled ? (
                    <MSWWrapper>{props.children}</MSWWrapper>
                ) : (
                    props.children
                )}
            </React.StrictMode>
        );
    }
    return props.children;
};

const MyApp = () => {
    const [isFeatureFlagInitialized, setIsFeatureFlagInitialized] =
        useState(false);

    useEffect(() => {
        // Load features asynchronously when the app renders
        gb.loadFeatures().then(() => {
            initializeFeatureFlag(gb); // Corrected here to use the `gb` instance
            setIsFeatureFlagInitialized(true); // Set the state to true once initialized
        });
    }, []);

    if (!isFeatureFlagInitialized) {
        return (
            <div className="container">
                <ScalersLoader />
            </div>
        ); // Render a loading state while waiting for initialization
    }

    return (
        <QueryClientProvider client={queryClient}>
            <GrowthBookProvider growthbook={gb}>
                <AppWrapper>
                    <_App />
                </AppWrapper>
            </GrowthBookProvider>
        </QueryClientProvider>
    );
};

export default MyApp;

ReactDOM.createRoot(rootElement).render(<MyApp />);
