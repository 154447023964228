import { useEffect } from "react";
import { Mixpanel } from "../Mixpanel";
import { captureEvent, trackTime } from "../Mixpanel";
import {
    find_missing_prop,
    prefix_to_object,
    free_trial_props,
} from "../utils";
import { getDefaultValues } from "../../utils/jobs/step2utils";
import { getUser } from "@/contexts/UserContext";
import {
    getActiveVertical,
    getSubscriptionDetails,
} from "@/mixpanel-tracking/utils";
import store from "@/core/stores";

export class JobFormTracking {
    state = store.getState();
    constructor(props) {
        this.stage = props.stage;
        this.values = props.values;
        this.page = props.page;
        this.value = props.value;
        this.page_enter = this.page + " Viewed";
        this.page_enter_exit = this.page_enter + " - Page Exited";
        this.currUser = getUser();
        this.from = props.from;
        this.duplicated = props.duplicated;
        this.vertical = this.state.selectedVertical.vertical;
    }

    page_view = (extraProps = {}) => {
        captureEvent(this.page_enter, {
            ...extraProps,
            ...(this.state?.number_jobs && {
                number_of_jobs: this.state.number_jobs,
            }),
            ...(this.from && {
                from: this.from,
            }),
        });
        trackTime(this.page_enter_exit);
    };
    page_exit = (extraProps) => {
        captureEvent(this.page_enter_exit, {
            ...prefix_to_object(this.values(), "form_filled_details_"),
            stage_when_left: this.stage,
            ...extraProps,
        });
    };
    usePageViewHook = (extraProps) => {
        useEffect(() => {
            this.page_view(extraProps);

            const trackOnTabClose = () => {
                this.page_exit(extraProps);
            };
            window.addEventListener("beforeunload", trackOnTabClose);
            return () => {
                window.removeEventListener("beforeunload", trackOnTabClose);
                this.page_exit(extraProps);
            };
        }, []);
    };
    job_created = (createdJobValues) => {
        captureEvent("Job Created", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...prefix_to_object(createdJobValues, "form_submitted_details_"),
            duplicated: this.duplicated,
        });
        Mixpanel.people.increment("numbers_jobs_created", 1);
    };
    job_edited = (extraProps) => {
        captureEvent("Job Edited", {
            ...extraProps,
            ...prefix_to_object(this.values(), "form_submitted_details_"),
        });
    };
    job_created_filters = (extraProps) => {
        captureEvent("Job Created", {
            ...free_trial_props(this.currUser),
            ...extraProps,
        });
        Mixpanel.people.increment("numbers_jobs_created", 1);
    };
    job_create_button_clicked = () => {
        captureEvent("Job Creation Button Clicked", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...getActiveVertical(this.vertical),
            ...prefix_to_object(this.currUser.company, "company_"),
            ...getSubscriptionDetails(this.currUser),
        });
    }
    job_creation_started = () => {
        captureEvent("Job Creation Started", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...getActiveVertical(this.vertical),
            ...getSubscriptionDetails(this.currUser),
            from: this.from,
        });
    };
    job_creation_submitted = (stage, createdJobValues, type) => {
        captureEvent("Job Creation Page Submit", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...prefix_to_object(createdJobValues, "form_submitted_details_"),
            stage,
            type,
        });
    };
    job_edit_viewed = (createdJobValues, source) => {
        captureEvent("Edit Job Viewed", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...prefix_to_object(createdJobValues, "form_submitted_details_"),
            source,
        });
    };
    need_help_clicked = (stage, questions) => {
        captureEvent("Need Help Button Clicked", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            questions,
            stage,
        });
    };
    need_help_submitted = (event, stage, feedback) => {
        captureEvent(event, {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            feedback,
            stage,
        });
    };
    job_duplicate_clicked = (createdJobValues, source) => {
        captureEvent("Job Duplication Button Clicked", {
            ...free_trial_props(this.currUser),
            ...prefix_to_object(this.currUser, "hp_"),
            ...prefix_to_object(createdJobValues, "form_submitted_details_"),
            source,
        });
    };
    go_to_guide = () => {
        captureEvent("Job Guide Button Clicked", {
            form_stage_when_clicked: this.stage.current,
            in_which_mode: this.page,
            ...prefix_to_object(this.values(), "form_filled_details_"),
        });
    };
    filter_removed = (type, filters, index, filters_before_change) => {
        let removed_filter = undefined;
        if (filters_before_change) {
            removed_filter = find_missing_prop(
                filters_before_change.value,
                filters[index].value
            );
        }
        captureEvent("Job Filter Removed", {
            type_of_removal: type,
            filter_id: filters[index].name,
            percentage_before_change: this.value,
            remaining_values: removed_filter ? filters[index].value : undefined,
            selected_before_deletion: removed_filter
                ? undefined
                : filters[index].value,
            removed_filter: removed_filter,
            ...prefix_to_object(this.values(), "form_filled_details_"),
        });
    };
    filter_added = (type, filters, index, filter, filters_before_addition) => {
        let added_filter = filter;
        if (filters_before_addition && Array.isArray(filters[index].value)) {
            added_filter = find_missing_prop(
                filters[index].value,
                filters_before_addition.value
            );
        }
        captureEvent("Job Filter Added", {
            specific_or_whole: type,
            filter_id: filters ? filters[index].name : filter.name,
            specific_filter_values: filters && filters[index].value,
            specific_filter_added: added_filter,
            filter_default_value: filter && getDefaultValues(filter.name),
            percentage_before_change: this.value,
            ...prefix_to_object(this.values(), "form_filled_details_"),
        });
    };
}
