import { RouteInterface } from "@/core/interfaces/route.interface";
import { lazy } from "react";

const ChangePhone = lazy(() => import("@/components/ChangePhone/ChangePhone"));
const VerticalsPage = lazy(
    () => import("@/pages/hp/components/Register/Verticals/VerticalsPage")
);
const Job = lazy(() => import("@/pages/hp/components/Register/Job/Job"));
const routes: Array<RouteInterface> = [
    {
        path: "change-phone",
        element: ChangePhone,
        name: "Change Phone",
    },
    {
        path: "hp/journey",
        element: VerticalsPage,
        name: "hp journey",
    },
    {
        path: "hp/journey/:vertical",
        element: Job,
        name: "Creation Job Journey",
    },
];

const GlobalRoutes = routes;
export default GlobalRoutes;
