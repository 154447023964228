import mixpanel from "mixpanel-browser";

if (import.meta.env.VITE_STAGE == "production") {
    mixpanel.init("4a0aa4242475841b1f983134d39a7909");
} else {
    // this will point to "Hiring Platform project on Mixpanel"
    mixpanel.init("70246dc9d3886994e3844492cafad230");
}

let env_check =
    import.meta.env.VITE_STAGE === "production" ||
    import.meta.env.VITE_STAGE === "development";

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
        increment: (props) => {
            if (env_check) mixpanel.people.increment(props);
        },
    },
    time_event: (until_event_name) => {
        if (env_check) mixpanel.time_event(until_event_name);
    },
    register: (props) => {
        if (env_check) mixpanel.register(props);
    },
};

export let Mixpanel = actions;

export function trackTime(until_event_name) {
    Mixpanel.time_event(until_event_name);
}

export function captureEvent(event_name, obj) {
    Mixpanel.track(event_name, obj);
    // I did the following to abide by the way it is currently done
    // On the landing page
    window.dataLayer.push({
        0: "event",
        1: event_name,
        2: { props: obj },
    });
}
