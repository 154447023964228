import { checkAndTriggerNotificationAnalytics } from "@/components/Login/utils";
import { getSetLookups } from "@/contexts/LookupsStateContext";
import { getSetUser } from "@/contexts/UserContext";
import { parse_from_path } from "@/mixpanel-tracking/utils";
import { CurrUserDetails } from "@/utils/currUserType";
import singletonLocalStorage from "@/utils/localStoring";
import {
    bootcamperNeedToContinue,
    genericNeedsToPlacement,
    getLookups,
    needsToFillPlacement,
} from "@/utils/utilFunctions";
import React, { useState, useEffect, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "@/../static/axios";

const RequireAuth: React.FC<{ children: React.ReactNode; isPublic?: boolean }> =
    memo(({ children, isPublic }) => {
        const location = useLocation();
        const navigate = useNavigate();
        const [tokenExpired, setTokenExpired] = useState(false);
        const setCurrUser = getSetUser();
        const setLookups = getSetLookups();
        useEffect(() => {
            if (!isPublic) {
                if (singletonLocalStorage.getItem("access_token") !== null) {
                    axiosInstance
                        .get("/user/currUser/")
                        .then((res) => {
                            getLookups(setLookups, res.data.views !== "hp");
                            setCurrUser(res.data);
                            checkAndTriggerNotificationAnalytics(
                                res.data,
                                parse_from_path(location.state?.search)
                            );
                            handleRedirection(res.data);
                        })
                        .catch((err) => {
                            err.response?.status === 401 &&
                                setTokenExpired(true);
                        });
                } else {
                    navigate("/", {
                        state: {
                            from: location.pathname,
                            search: location.search,
                        },
                    });
                }
            }
        }, []);

        const handleRedirection = (userData: CurrUserDetails) => {
            const from = location.pathname + location.search;
            if (genericNeedsToPlacement(userData)) {
                navigate("/candidate/generic-placement");
            } else if (bootcamperNeedToContinue(userData)) {
                navigate("/candidate/bt-placement");
            } else {
                if (
                    userData.needs_to_verify_phone &&
                    !userData.company?.journey &&
                    !userData.reset_password
                ) {
                    navigate("/change-phone");
                } else if (from) {
                    navigate(from);
                } else {
                    let link = userData.views;
                    switch (userData.views) {
                        case "hp":
                            link += "/candidates";
                            break;
                        case "candidate":
                            link += "/jobs";
                            break;
                        case "fast-tracker":
                            link = "candidate/ft-vetting-process";
                    }
                    navigate(link!, {
                        replace: true,
                        state: { source: "Wrong Page - Redirected here" },
                    });
                }
                if (
                    from &&
                    !from.includes("candidate/complete-placement") &&
                    needsToFillPlacement(userData)
                ) {
                    document.getElementById(
                        "complete-ur-profile"
                    )!.style.display = "flex";
                }
            }
        };
        if (tokenExpired) {
            singletonLocalStorage.removeItem("access_token");
            navigate("/?reason=token-expiry");
            return null;
        }
        return children;
    });
RequireAuth.displayName = "RequireAuth";
export default RequireAuth;
