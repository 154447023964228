import React, { memo, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getUser } from "@/contexts/UserContext";
import singletonLocalStorage from "@/utils/localStoring";
import { CurrUserDetails } from "@/utils/currUserType";
import { ExtraTracker } from "@/mixpanel-tracking/hp/extra-trackers";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { freeTrialEnded } from "@/utils/utilFunctions";

type Props = {
    locale: string;
    children: React.ReactNode;
};
const RequireSubscription: React.FC<Props> = memo(({ locale, children }) => {
    const currUser: CurrUserDetails = getUser() as CurrUserDetails;
    const factory = new Factory();
    const track = factory.get_tracker(Pages.EXTRA_TRACKER) as ExtraTracker;
    const location = useLocation();
    const navigate = useNavigate();

    if (freeTrialEnded(currUser)) {
        if (currUser.company) {
            track.block_page_redirect({
                free_trial: currUser.company.free_trial,
                free_trial_end_date: currUser.company.access_end_date,
                from: location.pathname,
                ...currUser,
            });
        }
        singletonLocalStorage.setItem("redirected", "true");
        singletonLocalStorage.removeItem("access_token");

        setTimeout(() =>
            navigate("/book-call", {
                replace: true,
                state: { from: location, user: currUser, locale: locale },
            })
        );
    }

    useEffect(() => {
        singletonLocalStorage.removeItem("skipFreeTrialRedirect");
    }, []);

    return <>{children}</>;
});

RequireSubscription.displayName = "RequireSubscription";
export default RequireSubscription;
