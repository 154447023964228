import { createSlice } from "@reduxjs/toolkit";

const notificationSnackbarInitialState = {
    showing: false,
    type: "success",
    variant: "outlined",
    hideDuration: 3000,
    relativeHorizontal: "start",
    vertical: "top",
    message: "",
    showIcon: false,
};

const generalSlice = createSlice({
    name: "general",
    initialState: {
        navigateAwayLink: "",
        notificationSnackbar: notificationSnackbarInitialState,
        restrictScreeningFreeTrialModal: {
            open: false,
            source: "",
            type: "",
            title: "",
            message: "",
            senjaWidgetEnId: "",
            senjaWidgetArId: "",
        },
    },
    reducers: {
        setNavigateAwayLink: (state, action) => {
            state.navigateAwayLink = action.payload;
        },
        setGeneralSnackbar: (state, action) => {
            state.notificationSnackbar = action.payload;
        },
        setGeneralSnackbarInitial: (state) => {
            state.notificationSnackbar = {
                ...state.notificationSnackbar,
                showing: false,
            };
        },
        openRestrictScreeningFreeTrialModal: (state, action) => {
            state.restrictScreeningFreeTrialModal = {
                open: true,
                source: action.payload.source,
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
                senjaWidgetEnId: action.payload.senjaWidgetEnId,
                senjaWidgetArId: action.payload.senjaWidgetArId,
            };
        },
    },
});

export const {
    setNavigateAwayLink,
    setGeneralSnackbar,
    setGeneralSnackbarInitial,
    openRestrictScreeningFreeTrialModal,
} = generalSlice.actions;

export default generalSlice.reducer;
