import React, { Dispatch, SetStateAction } from "react";

interface DefaultValue {
    locale: "en" | "ar";
    setLocale: Dispatch<SetStateAction<"en" | "ar">>;
}

const defaultValue: DefaultValue = {
    locale: "ar",
    setLocale: () => {},
};

export default React.createContext<DefaultValue>(defaultValue);
