import React, { useState } from "react";
import "../../static/css/banner.css";
import { useTranslation } from "react-i18next";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { free_trial_props } from "@/mixpanel-tracking/utils";
import CalendlyModal from "./Modals/CalendlyModal";
import { FeatureFlag } from "../feature-flag";
import { useSelector } from "react-redux";
import RestrictScreeningFreeTrialModal from "@/components/Modals/RestrictScreeningFreeTrialModal";

export default function FreeTrialBanner(props) {
    const { locale, user } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const selectedVertical = useSelector(
        (state) => state.selectedVertical.vertical
    );
    const restrictScreeningFreeTrialModal = useSelector(
        (state) => state.general.restrictScreeningFreeTrialModal
    );
    const factory = new Factory();
    let track = factory.get_tracker(Pages.EXTRA_TRACKER);

    const remainingTimeLabel = () => {
        const remainingTime = user.company.access_time_remaining;
        const hourInSeconds = 60 * 60;
        const dayInSeconds = hourInSeconds * 24;
        if (remainingTime < hourInSeconds) {
            return t("free_trial.banner.less_than_an_hour");
        } else if (remainingTime < dayInSeconds) {
            const hours = Math.ceil(remainingTime / hourInSeconds);
            return hours + " " + t("free_trial.banner.hours");
        } else {
            const days = Math.ceil(remainingTime / dayInSeconds);
            return days + " " + t("free_trial.banner.days");
        }
    };

    const remainingCandidatesLabel = () => {
        if (selectedVertical) {
            const remaining = user.company.free_trial_candidates.find(
                (i) => i.vertical === selectedVertical
            )?.remaining;

            return (
                (remaining >= 0 ? remaining : 0) +
                " " +
                t(
                    remaining > 1
                        ? "free_trial.banner.shortlists"
                        : "free_trial.banner.shortlist"
                )
            );
        }

        return "";
    };

    const handleButtonClick = () => {
        setOpen(true);
        track.banner_button_clicked({
            ...free_trial_props(user),
            ...user,
        });
    };

    return (
        <div className="free-trial-banner">
            {restrictScreeningFreeTrialModal.open && (
                <RestrictScreeningFreeTrialModal
                    isOpen={restrictScreeningFreeTrialModal.open}
                    source={restrictScreeningFreeTrialModal.source}
                    type={restrictScreeningFreeTrialModal.type}
                    title={restrictScreeningFreeTrialModal.title}
                    message={restrictScreeningFreeTrialModal.message}
                    senjaWidgetEnId={
                        restrictScreeningFreeTrialModal.senjaWidgetEnId
                    }
                    senjaWidgetArId={
                        restrictScreeningFreeTrialModal.senjaWidgetArId
                    }
                    onClose={() => {}}
                />
            )}
            <div className="free-trial-text">
                {t(
                    FeatureFlag.free_trial_B(user)
                        ? "free_trial.banner.phrase_one_b"
                        : "free_trial.banner.phrase_one_a"
                )}
                <span className="free-trial-text-bold">
                    {FeatureFlag.free_trial_A(user) && remainingTimeLabel()}
                    {FeatureFlag.free_trial_B(user) &&
                        remainingCandidatesLabel()}
                </span>
                <div className="free-trial-text">
                    {locale === "en"
                        ? t("free_trial.banner.phrase_three")
                        : t("free_trial.banner.phrase_two")}
                </div>
            </div>
            <div className="free-trial-textBtn">
                {locale === "en" && (
                    <span>{t("free_trial.banner.phrase_two")}</span>
                )}
                <div className="free-trial-button-wrap">
                    <button onClick={() => handleButtonClick()}>
                        {t("free_trial.banner.cta")}
                    </button>
                </div>
            </div>
            <div>
                <CalendlyModal
                    source="Free Trial Banner"
                    open={open}
                    setOpen={setOpen}
                />
            </div>
        </div>
    );
}
