import { lazy } from "react";
const RegisterForm = lazy(
    () => import("@/pages/hp/components/Register/RegisterForm/RegisterForm")
);

const BlockPage = lazy(() => import("@/components/BlockPage"));

const CalendarLoading = lazy(
    () =>
        import(
            "@/components/NewCandidateDetails/ActionsComponents/LoadingAccessFetch"
        )
);
const _AddEmployee = lazy(() => import("@/components/_AddEmployee"));
const _EmailVerify = lazy(() => import("@/components/_EmailVerify"));
const _VerifyEmailAndSetPass = lazy(
    () => import("@/components/_VerifyEmailAndSetPass")
);
const _ResetPassword = lazy(
    () => import("@/components/PasswordReset/_ResetPassword")
);
const DemoUser = lazy(() => import("@/components/DemoUser"));
const _ChooseResetPassword = lazy(
    () => import("@/components/PasswordReset/_ChooseResetPassword")
);
const _SetPassword = lazy(() => import("@/components/_SetPassword"));
const _EmailSentConfirm = lazy(
    () => import("@/components/PasswordReset/_EmailSentConfirm")
);
const _PasswordChanged = lazy(
    () => import("@/components/PasswordReset/_PasswordChanged")
);
const TokenExpired = lazy(() => import("@/components/TokenExpired"));
const _Login = lazy(() => import("@/components/Login/login.page"));

const NewLogin = lazy(() => import("@/components/Login/newlogin.page"));
const LandingGenericSendOTP = lazy(
    () => import("@/pages/candidate/generic-to-ft/LandingPage/LandingSendOTP")
);
const VerifyOTP = lazy(
    () => import("@/pages/candidate/generic-to-ft/VerifyOTP")
);
const SetPassword = lazy(
    () => import("@/pages/candidate/generic-to-ft/SetPassword/SetPassword")
);
const InterviewRejection = lazy(
    () => import("@/components/CandidateInterview/InterviewRejection")
);
const InterviewRejectionSuccess = lazy(
    () => import("@/components/CandidateInterview/InterviewRejectionSuccess")
);
const InterviewRejectionError = lazy(
    () => import("@/components/CandidateInterview/InterviewRejectionError")
);
const AlreadyVerifiedSet = lazy(
    () =>
        import(
            "@/pages/candidate/generic-to-ft/AlreadyVerifiedSet/AlreadyVerifiedSet"
        )
);

function routes(FeatureFlag) {
    return [
        {
            path: "",
            element: FeatureFlag.enable_new_sign_in_with_otp()
                ? NewLogin
                : _Login,
        },
        {
            path: "book-call",
            element: BlockPage,
        },
        {
            path: "calendar_loading",
            element: CalendarLoading,
        },
        {
            path: "create/:token",
            element: _AddEmployee,
        },
        {
            path: "verifyEmail/:id/:token",
            element: _EmailVerify,
        },
        {
            path: "verify_set_pass/:setPassToken/:verificationToken",
            element: _VerifyEmailAndSetPass,
        },
        {
            path: "reset_password",
            element: _ResetPassword,
        },
        {
            path: "demo",
            element: DemoUser,
        },
        {
            path: "set_password/:token",
            element: _ChooseResetPassword,
        },
        {
            path: "set_pwd_form/:token",
            element: _SetPassword,
        },
        {
            path: "email_sent",
            element: _EmailSentConfirm,
        },
        {
            path: "password_reset_confirm",
            element: _PasswordChanged,
        },
        {
            path: "token-error",
            element: TokenExpired,
        },
        {
            path: "to_ft/:user_token",
            element: LandingGenericSendOTP,
        },
        {
            path: "to_ft/verify/:user_token",
            element: VerifyOTP,
        },
        {
            path: "to_ft/set_password/:user_token",
            element: SetPassword,
        },
        {
            path: "to_ft/already_verified_and_set",
            element: AlreadyVerifiedSet,
        },
        {
            path: "login",
            element: FeatureFlag.enable_new_sign_in_with_otp()
                ? NewLogin
                : _Login,
        },
        {
            path: "hp/register",
            element: RegisterForm,
        },
        {
            path: "*",
            element: FeatureFlag.enable_new_sign_in_with_otp()
                ? NewLogin
                : _Login,
        },
        {
            path: "pipeline/interview-rejection",
            element: InterviewRejection,
        },
        {
            path: "pipeline/interview-rejection/success",
            element: InterviewRejectionSuccess,
        },
        {
            path: "pipeline/interview-rejection/error",
            element: InterviewRejectionError,
        },
    ];
}

export default routes;
