import React, { useState } from "react";
import Modal from "./Modal";
import { Box, Button, Stack } from "@mui/material";
import ScalersTypography from "../atoms/typography/typography";
import { useTranslation } from "react-i18next";
import CalendlyModal from "./CalendlyModal";
import LockOutlined from "@mui/icons-material/LockOutlined";
import i18n from "@/i18n";

function RestrictScreeningFreeTrialModal({
    source,
    isOpen,
    type,
    title,
    message,
    onClose,
    senjaWidgetEnId = '',
    senjaWidgetArId = '',
}) {
    const { t } = useTranslation();
    const [openCalendlyModal, setOpenCalendlyModal] = useState(false);
    const en = i18n.language === "en";

    const handleCloseModal = (reason) => {
        onClose(reason);
        setOpenCalendlyModal(false);
    };

    const handleBookCall = () => {
        handleCloseModal("book-call");
        setOpenCalendlyModal(true);
    };

    return (
        <>
            {isOpen && (
                <Modal
                    className={`restrict-screening-free-trial-modal ${ senjaWidgetEnId ? 'has-senja-widget' : '' }`}
                    type={type}
                    onClose={onClose}
                >
                    <Stack width="100%" gap="12px" alignItems="center">
                        <LockOutlined style={{ fontSize: 48 }} />
                        <ScalersTypography
                            style={{ marginTop: 4 }}
                            variant="subtitle"
                            fontSize="19px"
                            fontWeight={700}
                            color="#2A2731"
                        >
                            {title}
                        </ScalersTypography>
                        <ScalersTypography
                            variant="body2"
                            fontSize="13px"
                            fontWeight={400}
                            color="#55525A"
                        >
                            {message}
                        </ScalersTypography>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleBookCall}
                            >
                                {t("trial-duplicate-job.book-call")}
                            </Button>
                        </Box>
                    </Stack>
                    {senjaWidgetEnId && <div className="senja-widget-container"><div
                            className="senja-embed"
                            data-id={en ? senjaWidgetEnId : senjaWidgetArId}
                            data-mode="shadow"
                            data-lazyload="false"
                            style={{ display: "block" }}
                        ></div></div>
                    }
                </Modal>
            )}
            <CalendlyModal
                source={source}
                open={openCalendlyModal}
                setOpen={setOpenCalendlyModal}
            />
        </>
    );
}

export default RestrictScreeningFreeTrialModal;
