import { CurrUserDetails } from "@/utils/currUserType";
import { JobType } from "@/utils/jobType";
import { captureEvent } from "../Mixpanel";
import { Candidate } from "@/models/shared/candidate";
import { InterviewDetails } from "@/components/NewCandidateDetails/ActionsComponents/utils";
import { prefix_to_object } from "../utils";

export class InterviewTracking {
    constructor() {}

    interview_requested = (
        employee: CurrUserDetails,
        candidate: Candidate,
        job: JobType,
        request_details: InterviewDetails,
    ) => {
        captureEvent("Interview Requested", {
            ...prefix_to_object(employee.company, "company_"),
            ...prefix_to_object(candidate, "candidate_"),
            ...prefix_to_object(job, "job_"),
            employee_name: employee.name,
            employee_email: employee.email,
            interview_duration: request_details.length,
            interview_format: request_details.format.name_en,
            interview_time_frame: request_details.time_frame,
        });
    };

    availability_set = (employee: CurrUserDetails) => {
        captureEvent("Calendar Availability Set", {
            ...employee.company,
            employee_name: employee.name,
            employee_email: employee.email,
        });
    };
}
