import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useNotifications } from "../hooks/useNotifications";
import MessagesNotifyContext from "../contexts/MessagesNotifyContext";
import CuratedListNotifyContext from "../contexts/CuratedListNotifyContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
    getJobApplicants,
    getSetJobApplicants,
} from "@/contexts/JobsNotifyContext";
import { getUser } from "@/contexts/UserContext";
import useMediaBreakpoint from "@/hooks/useMediaBreakpoint";
import { isOnboarding } from "@/utils/utilFunctions";
import HeaderMobile from "./Header/HeaderMobile";
import CompleteProfileModal from "./Modals/CompleteProfileModal";
import FeedbackModal from "./Modals/FeedbackModal";
import InfoModal from "./Modals/InfoModal";
import LogoutModal from "./Modals/LogoutModal";
import SideBar from "./SideBar/SideBar";
import Modal from "@/components/Modals/Modal";
import new_applicant_img from "../../static/images/new_applicants.svg";
import FreeTrialBanner from "@/components/FreeTrialBanner";
import JobApplicantsBanner from "@/components/JobApplicantsBanner";
import { FeatureFlag } from "@/feature-flag";
import VerifyEmail from "./Modals/VerifyEmail";
import ExitDisclaimer from "./Modals/ExitDisclaimer";
import { JobNotifyProvider } from "@/contexts/JobsNotifyContext";
import { EmailVerificationProvider } from "@/EmailVerificationContext";
import { setUserSingleton } from "@/core/stores/user/user.store";
import Intercom from "@intercom/messenger-js-sdk";
import FreeTrialModal from "./Modals/FreeTrialModal";
import CryptoJS from "crypto-js";
import singletonLocalStorage from "@/utils/localStoring";

interface LayoutProps {
    locale: string;
}

interface IntercomCompany {
    company_id: string;
    name: string;
    plan?: string;
    website?: string;
    industry?: string;
    ["Company size range"]?: string;
    ["LinkedIn Page Link"]?: string;
    ["Subscription-starts-on"]?: number;
    ["Subscription end on"]?: number;
}

interface IntercomData {
    app_id: string;
    user_id: string | undefined;
    name: string | undefined;
    email: string | undefined;
    user_hash: string;
    company?: IntercomCompany;
    phone: string | undefined;
    env: string;
    "Employee Position"?: string;
    "Email domain"?: string;
}

const Layout: React.FC<LayoutProps> = ({ locale }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const currUser = getUser();
    const jobApplicants = getJobApplicants();
    const setJobApplicants = getSetJobApplicants();
    const { t } = useTranslation();
    const useMediaQuery = useMediaBreakpoint();
    const isMobileView = ["xs", "sm"].includes(useMediaQuery.breakpoint);
    const isDesktopView = !isMobileView;
    const [isSideBarOpen, setSideBarOpen] = React.useState(true);

    const {
        messagesNotify,
        setMessagesNotify,
        curatedListCount,
        setCuratedListCount,
        handleNotifications,
    } = useNotifications(jobApplicants, setJobApplicants);

    const changeLocale = useCallback(() => {
        i18n.changeLanguage(locale === "en" ? "ar" : "en");
    }, [locale]);

    const setSideBarVisibility = (visible: boolean) => {
        setSideBarOpen(visible);

        singletonLocalStorage.setItem("sidebar_open", `${visible}`);
    };

    useEffect(() => {
        if (singletonLocalStorage.getItem("sidebar_open")) {
            setSideBarOpen(
                singletonLocalStorage.getItem("sidebar_open") === "true"
            );
        }
    }, []);

    useEffect(() => {
        if (pathname !== "/" && currUser.views === "hp") {
            handleNotifications();
        }
    }, [pathname, currUser.views, handleNotifications]);

    useEffect(() => {
        if (
            (currUser?.views === "hp" || currUser?.views === "candidate") &&
            !document.getElementById("user_guiding_script")
        ) {
            const script = document.createElement("script");
            script.innerHTML =
                "(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','DE472584B4EID')";
            document.head.appendChild(script);
            window.userGuiding?.identify(currUser.id, {
                user_type: currUser.views,
                in_first_journey: isOnboarding(currUser),
                screen_size:
                    window.innerWidth < 900 ? "less than 900" : "900 or more",
                has_jobs: currUser.has_jobs,
                subscription_status:
                    currUser.company?.active_subscription.status,
                free_trial: currUser.company?.free_trial,
                subscription_tier: currUser.company?.active_subscription.tier,
            });
        }
        setUserSingleton(currUser);
    }, [currUser]);

    useEffect(() => {
        if (jobApplicants?.has_job_applicants) {
            const jobApplicantsElement =
                document.getElementById("go-job-applicants");
            if (jobApplicantsElement) {
                jobApplicantsElement.style.display = "flex";
            }
        }
    }, [jobApplicants]);

    if (FeatureFlag.intercom_visible()) {
        const secretKey = `${import.meta.env.VITE_INTERCOM_SECRET_KEY_HMAC}`;
        const userIdentifier = currUser.id;

        const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(
            CryptoJS.enc.Hex
        );
        const emailDomain = currUser.email?.split("@")[1] || "";

        const convertDateToUnix = (isoString: string): number => {
            const date = new Date(isoString);
            const unixTimestamp = Math.floor(date.getTime() / 1000);
            return unixTimestamp;
        };

        const intercomData: IntercomData = {
            app_id: "q42bvek0",
            user_id: currUser.id,
            name: currUser.name,
            email: currUser.email,
            user_hash: hash,
            phone: currUser?.phone_unique || currUser?.phone,
            ["env"]: `${import.meta.env.VITE_STAGE}`,
            "Employee Position": currUser?.role,
            "Email domain": emailDomain,
        };

        // Add company data only if it exists
        if (currUser.company) {
            intercomData.company = {
                company_id: currUser.company.id,
                name: currUser.company.name,
                plan: currUser.company.active_subscription?.tier,
                website: currUser.company.website,
                industry: currUser.company.industry,
                ["Company size range"]: currUser.company.size,
                ["LinkedIn Page Link"]: currUser.company?.linkedin,
                ["Subscription-starts-on"]: convertDateToUnix(
                    currUser.company?.active_subscription.activation_date
                ),
                ["Subscription end on"]: convertDateToUnix(
                    currUser.company?.active_subscription.expiration_date
                ),
            };
        }

        Intercom(intercomData);
    }

    return (
        <EmailVerificationProvider>
            <MessagesNotifyContext.Provider
                value={{ messagesNotify, setMessagesNotify }}
            >
                <CuratedListNotifyContext.Provider
                    value={{ curatedListCount, setCuratedListCount }}
                >
                    <JobNotifyProvider>
                        {currUser.views === "hp" && (
                            <>
                                {FeatureFlag.free_trial(
                                    currUser.views,
                                    currUser.company?.subscribed
                                ) &&
                                    isDesktopView && (
                                        <FreeTrialBanner
                                            locale={locale}
                                            user={currUser}
                                        />
                                    )}

                                {isDesktopView && <JobApplicantsBanner />}
                            </>
                        )}

                        {isMobileView && (
                            <HeaderMobile
                                messagesNotify={messagesNotify}
                                curatedListCount={curatedListCount}
                                jobApplicants={jobApplicants}
                                changeLang={changeLocale}
                                login={() => navigate("/")}
                            />
                        )}
                        <div
                            className={`inner-container ${currUser?.company?.free_trial ? "free-trial" : ""}`}
                        >
                            {isDesktopView && (
                                <SideBar
                                    messagesNotify={messagesNotify}
                                    curatedListCount={curatedListCount}
                                    jobApplicants={jobApplicants}
                                    isSideBarOpen={isSideBarOpen}
                                    setSideBarVisibility={setSideBarVisibility}
                                    changeLang={changeLocale}
                                />
                            )}
                            <div className="inner-container-outlet">
                                <Outlet context={[isSideBarOpen]} />
                            </div>
                        </div>

                        <Modal type="go-job-applicants">
                            <img
                                src={new_applicant_img}
                                className="new-job-applicants"
                                alt="New Job Applicants"
                            />
                            <Modal.JobApplicantsNumber
                                number={jobApplicants?.total_number}
                            />
                            <Modal.SingleMainAction
                                action={() =>
                                    navigate(jobApplicants.navigate_to)
                                }
                                buttonContent={t(
                                    "jobs_applicants_notice.button"
                                )}
                            />
                            <Modal.XButton />
                        </Modal>
                        <CompleteProfileModal type="complete-ur-profile" />
                        <InfoModal
                            type="you-need-to-complete-profile"
                            title={t("complete-ur-profile-modal.title2")}
                            buttonChild={
                                <Modal.SingleMainAction
                                    action={() => {}}
                                    buttonContent={t("form.okay")}
                                />
                            }
                        />
                        {currUser && (
                            <>
                                <FeedbackModal />
                                <LogoutModal t={t} />
                                <VerifyEmail t={t} locale={locale} />
                                <ExitDisclaimer />
                                <FreeTrialModal />
                            </>
                        )}
                    </JobNotifyProvider>
                </CuratedListNotifyContext.Provider>
            </MessagesNotifyContext.Provider>
        </EmailVerificationProvider>
    );
};

export default Layout;
