import LocaleContext from "@/contexts/LocaleContext";
import { getUser } from "@/contexts/UserContext";
import { ExtraTracker } from "@/mixpanel-tracking/hp/extra-trackers";
import { Factory, Pages } from "@/mixpanel-tracking/hp/factory";
import { prefix_to_object } from "@/mixpanel-tracking/utils";
import React, { useEffect } from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";

type Props = {
    source: string;
    setOpen: (state: boolean) => void;
    open: boolean;
};

export default function CalendlyModal(props: Props) {
    const { source, setOpen, open } = props;
    const { locale } = React.useContext(LocaleContext);
    const user = getUser();

    const factory = new Factory();
    const track = factory.get_tracker(Pages.EXTRA_TRACKER) as ExtraTracker;

    const en = locale == "en";
    const calendlyURL = en
        ? "https://calendly.com/scalers-team/subscription-plans-and-inquires-scalers"
        : "https://calendly.com/scalers-team/subscription-plans-and-inquires-scalers-ar";
    const pageSettings = {
        backgroundColor: "ffffff",
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: "var(--primary-500)",
        textColor: "4d5055",
    };
    const prefill = {
        email: user.email,
        name: en ? user.name_eng : user.name,
    };

    useEffect(() => {
        if(open) {
            track.popup_book_a_call_button_clicked({
                source,
                ...prefix_to_object(user, "hp_"),
            });
        }
    }, [open])

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            console.log(e.data.payload);
            track.book_call(user, e.data.payload);
        },
    });

    return (
        <PopupModal
            url={calendlyURL}
            prefill={prefill}
            pageSettings={pageSettings}
            onModalClose={() => setOpen(false)}
            open={open}
            rootElement={document.getElementById("root")!}
        />
    );
}
